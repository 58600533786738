import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { axiosProductsInstance } from '../dataFetch/axios.main';

import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../css/ProductDetails.css';
import { faFacebook, faInstagram, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import SideBar from './SideBar';
import { useCart } from '../context/CartContext';

import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import storeData from '../data/storeData'
import { useLocationContext } from '../context/LocationContext';
import useFacebookPixel from '../components/useFacebookPixel';


const Product = () => {
    const navigate = useNavigate();

    const {trackAddToCart, trackProductView} = useFacebookPixel();

    const { productId } = useParams();
    const { cartItems, addItemToCart, updateItemQuantity, getItemQuantity, isItemInCart, setShowSideBar } = useCart();

    const [isLoading, setIsloading] = useState(true);
    const [isSideBarVisible, setIsSideBarVisible] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [galleryImages, setGalleryImages] = useState([]);

    const [instockShops, setInstockShops] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);

    const {selectedLocation, setShowSelector, setIsOpenedInProductList } = useLocationContext();
    const alreadyRedirected = JSON.parse(localStorage.getItem('alreadyRedirected')) || false;

    const storeMapping = {
        ne: 'Nelson',
        gi: 'Gibsons',
        ke: 'Kelowna',
        ka: 'Kamloops',
        wv: 'West Vancouver',
        bu: 'Burnaby'
    }

    useEffect(() => {
        console.log('Current storeId in effect:', selectedLocation);
        fetchProduct(productId)
        fetchInstoreStock(productId)
    }, [productId, selectedLocation]);

    const fetchInstoreStock = async (productId) => {
        await axiosProductsInstance.get(`/stock/${productId}`).then(res => {
            const stockData = res.data;
            const storesWithStockStatus = storeData.map(store => {
                const blogId = Object.keys(storeMapping).find(key => storeMapping[key] === store.name);
                const match = stockData.find(item => item.blog_id === blogId);

                return {
                    ...store,
                    stock_status: match ? match.stock_status : 'null'
                }
    
            });
            const storesWithStock = storesWithStockStatus.filter(store => store.stock_status === 'instock');
            setInstockShops(storesWithStock);
            setSelectedStore(storesWithStock[0]);
        });

    };

    const fetchProduct = async (productId) => {
        setIsloading(true)
        const paramsObject = alreadyRedirected ? { blog_id: selectedLocation } : null;
        
        try {
            const res = await axiosProductsInstance.get(`/${productId}`, { params: paramsObject })
            const item = cartItems.find(cartItem => cartItem.id === Number(productId));
            const retrievedProduct = res.data;

            trackProductView(retrievedProduct.id);
            setQuantity(item ? item.quantity : 1);
            setProduct(retrievedProduct);
            if (retrievedProduct.length !== 0) {
                setGalleryImages([retrievedProduct.thumbnail, ...retrievedProduct.gallery_images]);
                setSelectedImage(retrievedProduct.thumbnail);
            }
            setIsloading(false)
        } catch (error) {
            console.log('Error fetching product:', error);
            setIsloading(false)
        } 

    };


    // Issue: Once you have one item in cart added you dont have the option to remove it form the ProductListItem
    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
        updateItemQuantity(product, Number(event.target.value));
        setShowSideBar(true);
    };

    const handleAddToCart = (product) => {

        if (!alreadyRedirected) {
            setIsOpenedInProductList(true);
            setShowSelector(true);
            return;
        } else {
            addItemToCart(product);
            setShowSideBar(true);
            trackAddToCart(product.id, product.blog_price);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'CAD',
                    add: {
                        products: [{
                            id: product.id,
                            name: product.name,
                            // category: product.category,
                            price: product.price,
                            quantity: 1
                        }]
                    }
                }
            });
        }
    };

    const handleBuyNow = (product) => {
        if (!alreadyRedirected) {
            setIsOpenedInProductList(true);
            setShowSelector(true);
            return;
        } else {
            addItemToCart(product);
            navigate('/checkout');
        }
    };

    const handleStoreChange = (event) => {
        const selectedId = parseInt(event.target.value, 10);
        const selectedStore = instockShops.find(store => store.id === selectedId);
        setSelectedStore(selectedStore);
    };

    useEffect(() => {
        const item = cartItems.find(cartItem => cartItem.id === Number(productId) || cartItem.slug === productId);
        setQuantity(item ? item.quantity : 1);
    }, [cartItems]);


    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };
    const processedAttributes = useMemo(() => {
        if (product.meta_data) {
            return product.meta_data
                .filter(item => !item.key.startsWith('fb') && !item.key.startsWith('fcrm') && !item.key.startsWith('_') && item.value && !item.key.includes('unit_of_sale'))  // Filter out keys with '_' and 'unit of sale'
                .map(item => ({
                    name: item.key,
                    options: [item.value],
                }));
        }
    }, [product.meta_data]);

    const blogSavedPercentage = ((product?.blog_regular_price - product?.blog_price) / product?.blog_regular_price) * 100;
    const savedPercentage = ((product?.regular_price - product?.price) / product?.regular_price) * 100;

    const roundedSavedPercentage = (percent) => {
        return Math.round(percent);
    } 

    function toTitleCase(str) {
        return str
            .replace(/_/g, ' ') // replace underscores with spaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
    function addBreaksToStrongTags(str) {
        return str
            .replace(/<strong>/g, '<br/><br/><strong>')
            .replace(/<\/strong>/g, '</strong><br/><br/>');
    }

    if (isLoading) {
        return (
            <>
                <div className="product-detail-wrapper">
                    <div className="image-gallery">
                        <div className="main-image-container">
                            <Skeleton style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }} />
                        </div>
                        <div className="image-thumbnails">
                            <Skeleton className="thumbnail" />
                            <Skeleton className="thumbnail" />
                            <Skeleton className="thumbnail" />
                        </div>
                    </div>
                    <div className="product-info-detail">
                        <div style={{ position: "relative", height: "36px", marginBottom: "5px" }}>
                            <Skeleton height={36} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", height: "36px", marginBottom: "30px" }}>
                            <Skeleton height={36} style={{ position: "absolute", left: "0", right: "0", width: "50%" }} />
                        </div>
                        <div style={{ position: "relative", height: "28px" }}>
                            <Skeleton height={28} width={120} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", margin: "14px 0" }}>
                            <Skeleton count={5} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", height: "28px" }}>
                            <Skeleton height={28} width={120} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>


                        <div style={{ position: "relative", height: "50px", marginTop: "30px" }}>
                            <Skeleton height={50} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", height: "50px", marginTop: "15px" }}>
                            <Skeleton height={50} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>

                        <div style={{ position: "relative", height: "20px", margin: "20px 0 15px" }}>
                            <Skeleton height={20} width={100} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ display: "flex" }}>
                            <Skeleton circle height={20} width={20} style={{ marginRight: "10px" }} />
                            <Skeleton circle height={20} width={20} style={{ marginRight: "10px" }} />
                            <Skeleton circle height={20} width={20} style={{ marginRight: "10px" }} />
                        </div>

                    </div>
                </div >

                <div className="product-description">
                    <Skeleton height={25} width={120} style={{margin: "20px 0"}}/>
                    <Skeleton count={5}/>
                    <Skeleton height={25} width={120} style={{margin: "20px 0"}}/>
                    <Skeleton count={5}/>
                </div>
            </>
        ); // or some loading spinner
    } else {
        // Now you can safely access product and selectedImage values
        return (
            product.length === 0 ? 
                <div className="product-detail-wrapper" style={{justifyContent: "center"}}>
                    <h1>Product not found</h1>
                </div>
                :
                (alreadyRedirected && product.blog_price === null) ? 
                    <div className="product-detail-wrapper" style={{justifyContent: "center"}}>
                        <h1>Product not found in current store</h1>
                    </div>
                    :
                    <>
                        <div className="product-detail-wrapper">
                            <div className="image-gallery">
                                <div className="main-image-container">
                                    <div className={product.blog_stock_status === "outofstock" ? "main-image out-of-stock" : "main-image"} style={{ backgroundImage: 'url(' + selectedImage + ')' }}>
                                    </div>
                                    {alreadyRedirected ? 
                                        <>
                                            {product.blog_on_sale && <span className="sale-label"></span>}
                                        </>
                                        :
                                        <>
                                            {product.on_sale && <span className="sale-label"></span>}
                                        </>
                                    }
                                    {product.virtual && <span className="instore-label"></span>}
                                    {product.tags.some(tag => tag.name === "Premium") && <span className="premium-label"></span>}
                                    {product.tags.some(tag => tag.name === "Feature") && <span className="feature-label"></span>}
                                </div>
                                <div className="image-thumbnails">
                                    {galleryImages.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Thumbnail ${index}`}
                                            className={`thumbnail ${selectedImage === image ? 'selected' : ''}`}
                                            onClick={() => handleThumbnailClick(image)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="product-info-detail">
                                <h2>{product.name}</h2>
                                <div>
                                    <span className="price">
                                        ${alreadyRedirected ? product.blog_price : product.price}
                                        <span className="price-uom">{product.unit}</span>
                                    </span>
                                    {alreadyRedirected ? 
                                        <>
                                            {product.blog_on_sale &&
                                                <span className="regular-price">
                                                    ${product.blog_regular_price}
                                                    <span className="price-uom">{product.unit}</span>
                                                </span>
                                            }
                                            {product.blog_on_sale &&
                                                <span className="sale-badge">Save {roundedSavedPercentage(blogSavedPercentage)}%
                                                </span>
                                            }
                                        </>
                                        :
                                        <>
                                            {product.on_sale &&
                                                <span className="regular-price">
                                                    ${product.regular_price}
                                                    <span className="price-uom">{product.unit}</span>
                                                </span>
                                            }
                                            {product.on_sale &&
                                                <span className="sale-badge">Save {roundedSavedPercentage(savedPercentage)}%
                                                </span>
                                            }
                                        </>
                                    }
                                </div>

                                <p className="product-biography" dangerouslySetInnerHTML={{ __html: product.description }}></p>

                                {product.virtual === true ? 
                                    <>
                                        <p style={{margin: "0 0 .5rem"}}><b>Available Stores</b></p>
                                        <div className="available-stores">
                                            <select name="stores" id="available_stores" className="available-stores-menu" onChange={handleStoreChange}>
                                                {instockShops.map((store, index) => {
                                                    return (
                                                        <option key={index} value={store.id}>{store.name}</option>
                                                    );
                                                })}
                                            </select>
                                            {selectedStore ? 
                                                <>
                                                    <p>{selectedStore.address}, {selectedStore.city}, {selectedStore.province} {selectedStore.zip}</p>
                                                    <p>{selectedStore.phone}</p>
                                                    <a href={selectedStore.mapLink} className="buy-now" target="_blank" rel="noreferrer" title="Google Map">VISIT HERE</a>
                                                </> : null
                                            }
                                        </div>
                                    </> 
                                    : 
                                    <>
                                        { product.blog_stock_status === "outofstock" ? 
                                            <>
                                                <button className="add-to-cart out-of-stock" disabled>OUT OF STOCK</button>
                                            </>
                                            :  
                                            <>
                                                <div className="quantity">
                                                    <label htmlFor="quantity">Quantity:</label>
                                                    <input type="number" id="quantity" name="quantity" min="1" value={quantity} onChange={handleQuantityChange} />
                                                </div>

                                                <button className="add-to-cart" onClick={() => handleAddToCart(product)}>ADD TO CART</button>
                                                <a onClick={() => handleBuyNow(product)} className="buy-now">BUY NOW</a>
                                            </>
                                        }
                                    </>
                                }

                                <div className="social-media-links">
                                    <p>Share this to</p>
                                    <div className="detail-social-icons">
                                        <a href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                                        <a href={`https://twitter.com/share?url=${window.location.href}&text=${product.name}`} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                                        <a href={`https://pinterest.com/pin/create/bookmarklet/?url=${window.location.href}&description=${product.name}`} target="_blank"><FontAwesomeIcon icon={faPinterest} /></a>
                                    </div>
                                </div>
                            </div>
                        </div >
                        {product.virtual === false &&
                            <div className="product-description">

                                {product.attributes.map((attribute, index) => (
                                    <div key={index}>
                                        <h3>{attribute.name}</h3>
                                        <p>{attribute.options[0]}</p>
                                    </div >
                                ))}

                                <div>
                                    {processedAttributes.map((attr, index) => (
                                        <div key={index}>
                                            <h3>{toTitleCase(attr.name)}</h3>
                                            {attr.options.map((option, i) => (
                                                <p key={i} dangerouslySetInnerHTML={{ __html: addBreaksToStrongTags(option) }}></p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        <SideBar />
                    </>
        )
    };
};

export default Product;
