import React, { useState } from 'react';
import '../css/PriceList.css';
import bannerImg from '../images/sustainable.png'
import fundraisers from '../images/fundraisers.png'
import logo1 from '../images/fundraiser_logo_1.jpeg'
import logo2 from '../images/fundraiser_logo_2.jpeg'
import logo3 from '../images/fundraiser_logo_3.jpeg'
import logo4 from '../images/fundraiser_logo_4.jpeg'
import logo5 from '../images/fundraiser_logo_5.jpeg'
import logo6 from '../images/fundraiser_logo_6.jpeg'
import logo7 from '../images/fundraiser_logo_7.jpeg'
import logo8 from '../images/fundraiser_logo_8.jpeg'
import logo9 from '../images/fundraiser_logo_9.jpeg'
import Recommends from '../components/Recommends';
import SignUpButton from '../components/SignUpButtom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import axios from 'axios';
import PopUp from '../components/PopUp';


const Fundraiser = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    organization: ''
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://thefishermansmarket.ca/wp-json/fundraiser/v1/submit', formData);
      if (response.data.success) {
        setMessage('Your application has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', organization: '' });
      } else {
        setMessage('There was an error submitting your application. Please try again.');
      }
    } catch (error) {
      setMessage('There was an error submitting your application. Please try again.');
    }
  };

  return (
    <div className="price-list">
      <div className="price-list-span" style={{ backgroundImage: 'url(' + bannerImg + ')' }}>
        <div className="price-list-span-wrapper">
          <h2 className="price-list-title">SEAFOOD AND MEAT <br />FUNDRAISERS</h2>
          <h3>Helping support our communities with quality sustainable food</h3>
        </div>
      </div>

      <div className="fundraiser-wrapper">

        <div className="fundraiser-content">
          <div className="fundraiser-content-left">
            <h1>30%+</h1>
          </div>
          <div className="fundraiser-content-right">
            <div className="padded">
              <h3>Over 30% of Proceeds Go Directly to Your Organization</h3>
              <p>Our fundraisers are designed to be easy and efficient for your organization.</p>
              <p>We take the workload, you take the profit.</p>
              <a href="#fundraiser" className="btn">Get Started</a>
            </div>
          </div>

        </div>

        <div className="fundraiser-content" style={{ borderRadius: "2rem", padding: "3rem", backgroundColor: "#FAFAF5" }}>
          <div className="fundraiser-content-right">
            <h3 style={{ fontFamily: 'pulpo-rust-100, serif', margin: "1rem", color: "#173050", fontSize: "calc(1vw + 40px)" }}>How it works</h3>
            <p>Fisherman’s Market provides a customized order form with specialized product options tailored to your needs. We handle all customer orders, requests, pickups, and payments.</p>
            <p>All you and your team need to do is collect orders and enjoy the rewards!</p>
            <a href="#fundraiser" className="btn">Get Started</a>
          </div>
        </div>
        <div className="fundraiser-content" style={{ flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}><img src={fundraisers} alt="fundraisers" style={{ width: "50%" }} /></div>
          <p style={{ textAlign: "center", fontSize: "2rem" }}>With retail locations across the province, you can make your fundraiser as wide spread or as focused as you want.</p>
        </div>
        <div className="fundraiser-content" style={{ flexDirection: "column" }}>
          <div className="fundraiser-content-right">
            <h3 style={{ fontFamily: 'pulpo-rust-100, serif', margin: "1rem 0 3rem", color: "#173050", fontSize: "calc(1vw + 40px)" }}>We're proud to support</h3>
            <Swiper
              slidesPerView={window.innerWidth > 1024 ? 5 : 2}
              spaceBetween={50}
              loop={true}
              navigation
              centeredSlides={true}
              centerInsufficientSlides={true}
              centeredSlidesBounds={true}
              modules={[Autoplay, Pagination, Navigation, Scrollbar, A11y]}
              className="mySwiper"
            >
              <SwiperSlide className='logos'><img src={logo1} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo2} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo3} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo4} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo5} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo6} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo7} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo8} /></SwiperSlide>
              <SwiperSlide className='logos'><img src={logo9} /></SwiperSlide>
            </Swiper>
          </div>
          <div className="fundraiser-content-right">

            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              loop={true}
              navigation={false}
              pagination={{ clickable: true }}
              centeredSlides={true}
              centerInsufficientSlides={true}
              centeredSlidesBounds={true}
              modules={[Autoplay, Pagination, Navigation, Scrollbar, A11y]}
              autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
              className="testimonials"
            >
              <SwiperSlide className='words'>
                <p>“When we heard of the uniqueness of the Fisherman’s Market Fundraisers we jumped at the chance. We were excited to offer something different to our players and the association. We were surprised at how simple everything was and how high quality the products were. We already have people asking for more! To top it all off the communication throughout the process was second to none from start to finish. If you're looking for a new fundraising opportunity, look no further than Fisherman’s Market.”</p>
                <p>-Kevin, Langley Thunder Jr A Lacrosse</p>
              </SwiperSlide>
              <SwiperSlide className='words'>
                <p>“Fisherman’s Market is the epitome of a community-minded small-town business. For years they have graciously supported our basketball program through their annual team fundraiser. Their generosity and support have helped elevate our school sports teams and athletic departments. The Fisherman’s Market provides our communities with so much more than quality, sustainable seafood! Thanks for all your support.”</p>
                <p>-Ryan M, LV Rogers Secondary</p>
              </SwiperSlide>
              <SwiperSlide className='words'>
                <p>“We appreciate Fisherman’s Market’s creative idea of partnering with Heart and Stroke for a Heart Healthy fundraiser across the province! Heartfelt thanks to everyone on the team for supporting our mission of saving as many lives as possible from heart disease and stroke across the country.”</p>
                <p>-Mandy W, Heart and Stroke Foundation</p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="fundraiser-content" style={{ borderRadius: "2rem", padding: "1rem", backgroundColor: "#FAFAF5" }}>
          <div className="fundraiser-content-right">
            <h3 id="fundraiser" style={{ fontFamily: 'pulpo-rust-100, serif', margin: "1rem 0", color: "#173050", fontSize: "calc(1vw + 40px)" }}>Start Fundraising</h3>
            <form onSubmit={handleSubmit} className='fundraiser-form'>
              <div className="fundraiser-form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="fundraiser-form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="fundraiser-form-group">
                <label htmlFor="phone">Phone</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
              </div>
              <div className="fundraiser-form-group">
                <label htmlFor="organization">Organization</label>
                <input type="text" id="organization" name="organization" value={formData.organization} onChange={handleChange} required />
              </div>
              <button type="submit">SUBMIT</button>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>

      </div>
      <Recommends />
      <SignUpButton />
      <PopUp/>

    </div>
  );
}

export default Fundraiser;
