/* global fbq */

import { useEffect } from 'react';

function useFacebookPixel() {
  const trackAddToCart = (productId, price) => {

    const params = {
      content_ids: [productId.toString()],
      content_type: 'product',
      value: parseFloat(price, 10),
      currency: 'CAD',
    }

    fbq('track', 'AddToCart', params);

    console.log('trackAddToCart', params);
  };

  const trackPurchase = (productId, price) => {
    fbq('track', 'Purchase', {
      content_ids: [productId.toString()],
      value: parseFloat(price, 10),
      currency: 'CAD',
      content_type: 'product',
    });
  };

  const trackProductView = (productId) => {
    fbq('track', 'ViewContent', {
      content_ids: [productId.toString()],
      content_type: 'product',
    });
  };

  // useEffect(() => {
  //   fbq('track', 'PageView');
  // }, []);

  return { trackAddToCart, trackPurchase, trackProductView };
}

export default useFacebookPixel;