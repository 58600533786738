import React, { useState, useEffect, useMemo } from 'react';
import { useCheckout } from '../../context/CheckoutContext';
import { useUser } from '../../context/UserContext';
import "../../css/Checkout.css";
import { useCart } from '../../context/CartContext';
import { useLocationContext } from '../../context/LocationContext';
import storeData from '../../data/storeData';

const CustomerShipping = () => {
    // Contexts
    const { updateShippingInfo, checkoutOptionsNew, shippingCost, dispatchCheckoutOptions, pickupLocations, updatePickupLocationAddress } = useCheckout();
    const { calculateTotalCartPrice } = useCart()
    const { userDetails, isLoggedIn } = useUser();
    const eligibleShippingCities = ['vancouver', 'west vancouver', 'north vancouver', 'east vancouver', 'south vancouver', 'burnaby', 'new westminster', 'port moody', 'ubc', 'university of british columbia', 'downtown', 'deep cove', 'horseshoe bay', 'port coquitlam', 'coquitlam', 'penticton', 'west kelowna', 'summerland', 'peachland', 'lake country', 'vernon', 'salmon arm', 'armstrong'];
    const [cityError, setCityError] = useState(false);
    const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState(null);


    // const [countryid, setCountryid] = useState(0);
    // const [stateid, setstateid] = useState(0);

    const handleNewCheckoutOptions = async (type, payload) => {
        console.log("handleNewCheckoutOptions", type, payload)
        switch (type) {
            case 'SET_STORE_PICKUP':
                dispatchCheckoutOptions({ type, payload });
                dispatchCheckoutOptions({ type: 'SET_STORE_DELIVERY', payload: false });
                dispatchCheckoutOptions({ type: 'SET_SHIPPING_COST', payload: 0.00 });

                break;
            case 'SET_STORE_DELIVERY':
                dispatchCheckoutOptions({ type, payload });
                dispatchCheckoutOptions({ type: 'SET_STORE_PICKUP', payload: false });
                await checkShippingInfoAndCalculateCost();
                break;
            default:
                break;
        }
    };

    const handlePickupLocationChange = (value) => {
        dispatchCheckoutOptions({ type: 'SET_PICKUP_LOCATION', payload: { name: value, pickupLocations: pickupLocations } });
    };
    useEffect(() => {
        handlePickupLocationChange(pickupLocations[0]?.name);
    }, [pickupLocations]);

    const handlePickupOrderNotes = (value) => {
        console.log("handlePickupOrderNotes: ", value)
        dispatchCheckoutOptions({ type: 'SET_ORDER_NOTES', payload: value });
    };

    const [shippingInfo, setShippingInfo] = useState(() => {
        if (isLoggedIn) {
            const savedUserShippingInfo = JSON.parse(localStorage.getItem("userDetails"))?.shipping;
            return savedUserShippingInfo ? savedUserShippingInfo : {
                first_name: "",
                last_name: "",
                address_1: "",
                city: "",
                postcode: "",
                country: "Canada",
                state: "British Columbia",
                phone: "",
                order_notses: ""
            };
        } else {
            const savedGuestShippingInfo = JSON.parse(localStorage.getItem("shippingInfo"));
            return savedGuestShippingInfo ? savedGuestShippingInfo : {
                first_name: "",
                last_name: "",
                address_1: "",
                city: "",
                postcode: "",
                country: "Canada",
                state: "British Columbia",
                phone: "",
                order_notes: ""
            };
        }
    });
    const [showDeliveryMessege, setDeliveryMessage] = useState(shippingInfo?.city ? true : false);
    const checkShippingInfoAndCalculateCost = async () => {
        const state = shippingInfo?.state;
        const city = shippingInfo?.city;
        // console.log("shippingInfo before calculate: ", shippingInfo)
        // Check if city and state are provided
        if (city.trim() !== "" && state.trim() !== "" && city.length > 3) {
            try {
                shippingCost.mutate({ province: state, city: city, cartTotal: calculateTotalCartPrice() })

            } catch (error) {
                console.error("Error calculating shipping cost:", error);
                // setShippingCost(null); // Handle errors, possibly resetting or setting to a default state
            }
        } else {
            console.log('Please ensure the city and province are filled out.');
        }
    };

    const relevantInfo = useMemo(() => ({
        city: shippingInfo.city,
        state: shippingInfo.state,
    }), [shippingInfo.city, shippingInfo.state]);

    useEffect(() => {
        checkShippingInfoAndCalculateCost();
    }, [relevantInfo]); // Listens to changes in the memoized value


    useEffect(() => {
        updateShippingInfo(shippingInfo);
    }, [shippingInfo]);

    useEffect(() => {
        const savedUserShippingInfo = JSON.parse(localStorage.getItem('userDetails'));
        const shipping = savedUserShippingInfo ? savedUserShippingInfo.shipping : {
            first_name: "",
            last_name: "",
            address_1: "",
            city: "",
            postcode: "",
            country: "Canada",
            state: "British Columbia",
            phone: "",
            order_notes: ""
        }
        setShippingInfo(shipping);
    }, [userDetails])

    const isDeliverable = (city) => {
        return eligibleShippingCities.includes(city);
    }

    const handleOnFocusOut = (city) => {
        if (!isDeliverable(city.toLowerCase())) {
            setDeliveryMessage(false);
            setCityError(true);
            setShippingInfo(prev => ({ ...prev, city: "" }));
        } else {
            setDeliveryMessage(true);
            setCityError(false);
            const currentDate = new Date();
            const newEstimatedDeliveryTime = new Date(currentDate.getTime() + (3 * 24 * 60 * 60 * 1000));
            setEstimatedDeliveryTime(newEstimatedDeliveryTime);
        }
    };

    const handleChange = (section, key, target) => {
        // console.log("Delivery info: ", section, key, value)
        switch (section) {
            case 'shippingInfo':
                if (target.value) {
                    target.classList.remove('error');
                    if (key === 'city') {
                        if (isDeliverable(target.value.toLowerCase())) {
                            setDeliveryMessage(true);
                            setCityError(false);
                            const currentDate = new Date();
                            const newEstimatedDeliveryTime = new Date(currentDate.getTime() + (3 * 24 * 60 * 60 * 1000));
                            setEstimatedDeliveryTime(newEstimatedDeliveryTime);
                        } else {
                            setDeliveryMessage(false);
                            setEstimatedDeliveryTime("");
                            setCityError(true);
                        }
                    }
                }
                setShippingInfo(prev => ({ ...prev, [key]: target.value }));
                break;
            default:
                break;
        }
    }

    const handleOrderNotes = (target) => {
        console.log("Shipping updating order notes")
        handleChange('shippingInfo', 'order_notes', target)
    }

    return (
        <>
            <div className="pickup-store-form">
                <fieldset className="pickup-store-fieldset">
                    <legend>Pickup Store</legend>
                    {/* Delivery Type */}
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                // value={checkoutOptions.storePickup}
                                name='storePickup'
                                // disabled={checkoutOptions.storePickup ? true : false}
                                checked={checkoutOptionsNew.storePickup}
                                onChange={(e) => handleNewCheckoutOptions('SET_STORE_PICKUP', e.target.checked)}
                            />
                            Store Pickup
                        </label>
                        <label>
                            <input
                                type="radio"
                                // value={checkoutOptions.storeDelivery}
                                // disabled={checkoutOptions.storePickup ? true : false}
                                name='storeDelivery'
                                checked={checkoutOptionsNew.storeDelivery}
                                onChange={(e) => handleNewCheckoutOptions('SET_STORE_DELIVERY', e.target.checked)}
                            />
                            Deliver
                        </label>
                    </div>
                    {checkoutOptionsNew.storePickup ?
                        <div className="form-group-checkout">
                            <label htmlFor="paymentType" className="required">Pickup Location</label>
                            
                            <select
                                required
                                id="pickUpType"
                                className="form-control"
                                placeholder="Choose a Pickup Location"
                                value={checkoutOptionsNew.pickupLocation?.name || ''}
                                onChange={(e) => handlePickupLocationChange(e.target.value)}
                            >
                                <option key={-1} value="" disabled>
                                    Choose a Pickup Location
                                </option>
                                {pickupLocations.map((location, index) => (
                                    <option key={index} value={location.name} >
                                        {location.name}
                                    </option>
                                ))}
                            </select>

                            {checkoutOptionsNew.pickupLocation.name ?
                                <div className='location-address'>Address: <br/>{checkoutOptionsNew.pickupLocation?.address.city} - {checkoutOptionsNew.pickupLocation.address.address_1}</div>
                                :
                                <div className='location-address'>No Pickup Location Set</div>
                            }
                            <label>Order Notes:</label>
                            <textarea className="order_notes_textarea" rows="4"
                                placeholder="Notes about your order. e.g., Special notes for delivery. (Optional)"
                                onBlur={(e) => handlePickupOrderNotes(e.target.value)}
                            />
                        </div>
                        : ""
                    }
                    {!checkoutOptionsNew.storePickup ?
                        <div className="column-group" id="deliveryForm">
                            <div className="column-group-item">
                                <label className="column-group-item-label">Country: Canada</label>
                            </div>
                            <div className="column-group-item">
                                <label className="column-group-item-label">Province: British Columbia</label>
                            </div>
                            <div className="column-group-item">
                                <label className="column-group-item-label">Address:</label>
                                <input
                                    value={shippingInfo?.address_1}
                                    type="text"
                                    onChange={(e) => handleChange('shippingInfo', 'address_1', e.target)} required />
                            </div>
                            <div className="column-group-item">
                                <label className="column-group-item-label">City:</label>
                                <input
                                    value={shippingInfo?.city}
                                    type="text"
                                    onFocusOut={(e) => handleOnFocusOut(e.target.value)}
                                    onChange={(e) => handleChange('shippingInfo', 'city', e.target)}
                                    className={cityError ? 'error' : ''}
                                    required />
                            </div>
                            {cityError && <p className="error-message" style={{ color: "red" }}>This city is not eligible for delivery.</p>}

                            <div className="column-group-item">
                                <label className="column-group-item-label">Postcode:</label>
                                <input
                                    value={shippingInfo?.postcode}
                                    type="text"
                                    onChange={(e) => handleChange('shippingInfo', 'postcode', e.target)} required />
                            </div>
                            <div className="column-group-item">
                                <label className="column-group-item-label">Phone:</label>
                                <input
                                    value={shippingInfo?.phone}
                                    type="text"
                                    onChange={(e) => handleChange('shippingInfo', 'phone', e.target)} required />
                            </div>
                            <div className="column-group-item">
                                <label className="column-group-item-label">Notes:</label>
                                <textarea rows="4" style={{ width: "100%" }}
                                    placeholder="Notes about your order. e.g., Special notes for delivery.(Optional)"
                                    onBlur={(e) => handleOrderNotes(e.target)}
                                />
                            </div>
                            {showDeliveryMessege ? (
                                <p>
                                    Estimated delivery time: {estimatedDeliveryTime ? estimatedDeliveryTime.toLocaleDateString() : 'Loading...'}
                                    . <br /><i>The specific time is subject to actual order processing. Please pay attention to the order status at any time.</i>
                                </p>
                            ) : (
                                ""
                            )}
                            <div>
                                <p style={{ fontSize: "small", fontWeight: "bold" }}>
                                    Discover our shipping policies tailored for the Lower Mainland of Vancouver and the Okanagan region.
                                    <span> <a href='https://thefishermansmarket.ca/shipping' target='_blank'>See details</a></span></p>
                                <p style={{ fontSize: "small", fontWeight: "bold" }}>
                                    Free Shipping: Orders over $250 qualify for free delivery, offering great value for your bulk purchases.                                </p>
                            </div>
                        </div>


                        : ""
                    }
                </fieldset >
            </div >

        </>
    );
};

export default CustomerShipping;