import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCartShopping, faUser, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../context/CartContext';

// .product-item-container {
//   display: flex;
//   padding: 1rem;
//   flex-direction: row;
//   justify-content: space-between;
//   border-radius: 0.25rem;
// }

// .product-image-cart {
//   width: 80px; /* 80px */
//   height: 80px; /* 80px */
// }

import { tv } from 'tailwind-variants';
const orderComponent = tv({
    slots: {
        orderItemQuantityCSS: '',
        orderQuantityContainerCSS: '',
        orderItemContainerCSS: 'flex flex-row p-4 justify-between rounded-lg',
        orderItemImageCSS: 'h-28 w-28 '

  }
});

const {  orderItemQuantityCSS, orderQuantityContainerCSS, orderItemContainerCSS, orderItemImageCSS} = orderComponent();

const OrderItem = ({ item }) => {
    const { removeItemFromCart, addItemToCart, removeAllItemsOfType, calculateItemTotalPrice } = useCart();
    return (
        <div className={ orderItemContainerCSS()}>
            <div><img className={ orderItemImageCSS()} src={item.image.src} /></div>
            <div className='font-bold place-self-center'>{item.name}</div>
            <div>
                <div className='font-bold'>Price</div>
                <div className='text-xs'>C$ {item.price}</div>
            </div>
            <div className={ orderQuantityContainerCSS()}>
                <div className=''>Quantity</div>
                <div className={ orderItemQuantityCSS()}>
                    {/* <div onClick={() => removeItemFromCart(item.id)}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></div> */}
                    <div>{item.quantity}</div>
                    {/* <div onClick={() => addItemToCart(item)}> <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></div> */}
                </div>
            </div>
            <div>
                <div className='text-right'>Total Price</div>
                <div className='text-right'>C$ {calculateItemTotalPrice(item)}</div>
                <div className='details-remove'>
                    <div>View Full Details</div>
                    {/* <div onClick={() => removeAllItemsOfType(item.id)}>Remove</div> */}
                </div>
            </div>
        </div >
    )
}
export default OrderItem;

