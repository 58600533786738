import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { set } from 'react-hook-form';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleSubmit = async (event) => {
        if (email === "" || code === "" || newPassword === "") {
            alert('Please fill in all fields');
            return;
        }
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('https://thefishermansmarket.ca/wp-json/simple-jwt-login/v1/user/reset_password', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    code: code,
                    new_password: newPassword,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to reset password');
            }
            toast.success('Your Password has been reset.');
            // wait a few seconds and redirect to the login page
            setTimeout(() => {
                window.location.href = '/customer/sign-in';
            }, 3000);
        } catch (error) {
            toast.error("Failed to reset password.");
        } finally {
            setLoading(false);
        }
    };
    const handleEmailChange = (event) => {
        event.preventDefault();
        setEmail(event.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(event.target.value);
        const input = event.target;
        if (input.value !== "") {
            input.classList.add("filled");
            if (!isValidEmail) {
                // Handle invalid email format
                input.classList.add("error");
                setIsValidEmail(false);
            } else {
                input.classList.remove("error");
                setIsValidEmail(true);
            }
        } else {
            input.classList.remove("filled");
            input.classList.remove("error");
            setIsValidEmail(true);
        }
    };

    const handleCodeChange = (event) => {
        event.preventDefault();
        setCode(event.target.value);
        if (event.target.value !== "") {
            event.target.classList.add("filled");
        } else {
            event.target.classList.remove("filled");
        }
    }

    const handleNewPasswordChange = (event) => {
        event.preventDefault();
        setNewPassword(event.target.value); if (event.target.value !== "") {
            event.target.classList.add("filled");
        } else {
            event.target.classList.remove("filled");
        }
        if (event.target.value !== "") {
            event.target.classList.add("filled");
        } else {
            event.target.classList.remove("filled");
        }
    }

    return (
        <div className="form-container">
            <div className="form-wrapper">
                <div className="form-content">

                    <div className="form-content-title">Please Enter Your New Password</div>
                    <form onSubmit={handleSubmit} className="form-content-body">
                        <div className="form-group">
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="form-input" required={true}
                            />
                            <label className="form-label" htmlFor="email">{/* <FontAwesomeIcon icon={faUser} /> */} Email</label>
                            {!isValidEmail && <div className="error-message">Invalid Email Address</div>}
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                className="form-input" required={true}
                            />
                            <label className="form-label" htmlFor="">{/* <FontAwesomeIcon icon={faUser} /> */} New Password</label>
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                value={code}
                                onChange={handleCodeChange}
                                className="form-input" required={true}
                            />
                            <label className="form-label" htmlFor="">{/* <FontAwesomeIcon icon={faUser} /> */} Reset Code</label>
                        </div>
                        <button type="submit" disabled={loading} className="form-button">
                            {loading ? 'Resetting...' : 'RESET PASSWORD'}
                        </button>
                    </form>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                toastOptions={{
                    error: {
                        duration: 5000,
                    }
                }}
                reverseOrder={false} />
        </div>
    );
};

export default ResetPassword;
