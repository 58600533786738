import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/CountdownBanner.css';
import { data } from 'autoprefixer';

// 10-04-00:00 - 10-08-00:00 - 10-13-00:00 - 10-13-23:59

const CountdownBanner = ({ smSize, isShowButton, dataToParent }) => {

    const [countdownData, setCountdownData] = useState({
        dates: {
            start_date: null,
            node_date_1: null,
            node_date_2: null,
            target_date: null
        },
        phases: {
            1: { title: '', subtitle: '', coupon_code: '' },
            2: { title: '', subtitle: '', coupon_code: '' },
            3: { title: '', subtitle: '', coupon_code: '' }
        }
    });
    const [timeLeft, setTimeLeft] = useState({});
    const [currentPhase, setCurrentPhase] = useState(1);

    const [showBanner, setShowBanner] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const updateData = () => {
        if (!showBanner) {
            return dataToParent('');
        };
        dataToParent(countdownData?.phases[currentPhase].coupon_code);
    }

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const response = await fetch('https://thefishermansmarket.ca/wp-json/countdown/v1/countdown-data');
                const data = await response.json();
                setCountdownData({
                    dates: {
                        start_date: new Date(data.dates.start_date),
                        node_date_1: new Date(data.dates.node_date_1),
                        node_date_2: new Date(data.dates.node_date_2),
                        target_date: new Date(data.dates.target_date)
                    },
                    phases: data.phases
                });
            } catch (error) {
                console.error('Error fetching dates:', error);
            }
        };

        fetchDates();
        const intervalId = setInterval(() => {
            fetchDates();
        }, 60000); // Refresh every minute

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        updateData();

    }, [currentPhase, showBanner]);

    useEffect(() => {
        const { dates } = countdownData;
        if (!dates.start_date || !dates.node_date_1 || !dates.node_date_2 || !dates.target_date) return;

        const timer = setInterval(() => {
            const now = new Date();
            let targetDate;
            let shouldShow = false;

            if (now >= dates.start_date && now < dates.node_date_1) {
                setCurrentPhase(1);
                targetDate = dates.node_date_1;
                shouldShow = true;
            } else if (now >= dates.node_date_1 && now < dates.node_date_2) {
                setCurrentPhase(2);
                targetDate = dates.node_date_2;
                shouldShow = true;
            } else if (now >= dates.node_date_2 && now < dates.target_date) {
                setCurrentPhase(3);
                targetDate = dates.target_date;
                shouldShow = true;
            }

            setShowBanner(shouldShow);

            if (shouldShow) {
                const difference = targetDate.getTime() - now.getTime();
                setTimeLeft({
                    Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    Hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    Min: Math.floor((difference / 1000 / 60) % 60),
                    Sec: Math.floor((difference / 1000) % 60)
                });
            } else {
                setTimeLeft({ D: 0, H: 0, M: 0, S: 0 });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [countdownData]);

    const addLeadingZero = (value) => {
        return value < 10 ? `0${value}` : value;
    };

    const { title, subtitle } = countdownData.phases[currentPhase];

    const bannerClassName = () => {
        if (smSize) {
            switch (currentPhase) {
                case 1: return "cd-banner sm pre";
                case 2: return "cd-banner sm cur";
                case 3: return "cd-banner sm last";
            }
        } else {
            switch (currentPhase) {
                case 1: if (scrolled) return "cd-banner pre scrolled"; return "cd-banner pre";
                case 2: if (scrolled) return "cd-banner cur scrolled"; return "cd-banner cur";
                case 3: if (scrolled) return "cd-banner last scrolled"; return "cd-banner last";
            }
        }
    }

    if (!showBanner) return null;

    return (
        <div className={bannerClassName()}>
            <div className={smSize ? "cd-banner-wrapper sm" : "cd-banner-wrapper"}>
                <div className={smSize ? "cd-banner-container sm" : "cd-banner-container"}>
                    <div className="cd-banner-content">
                        <h2 className="cd-banner-subtitle" >{title}</h2>
                        <p className="cd-banner-title" >{subtitle}</p>
                    </div>
                    <div className="cd-banner-function">
                        <div className="cd-banner-ticker">
                            <span>Only </span>
                            {Object.entries(timeLeft).map(([unit, value]) => (
                                <div key={unit} className="cd-banner-ticker-section">
                                    <span className="cd-banner-ticker-value">{addLeadingZero(value)}</span>
                                    <span className="cd-banner-ticker-unit">{unit}</span>
                                </div>
                            ))}
                            <span> Left!</span>
                        </div>
                        {isShowButton ? (
                            currentPhase === 1 ? (
                                <Link to="/fest-info" className="cd-banner-button">LEARN MORE</Link>
                            ) : (
                                <Link to="/shop" className="cd-banner-button">SHOP NOW</Link>
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownBanner;