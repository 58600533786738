import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useCart } from '../../context/CartContext';
import { tv } from 'tailwind-variants';

import OrderItem from './OrderItem';

import '../../css/ProductItem.css'

const orderComponent = tv({
  slots: {
    orderCSS: 'flex flex-col shadow gap-4 bg-gray-50 border border-gray-200 pb-8 rounded-lg',
    orderHeaderCSS: 'bg-fmBlue px-4 py-8 flex flex-row justify-between items-center border-b border-gray-400 rounded-t-lg',
    orderHeaderColCSS: 'bg-fmBlue text-white rounded-full shrink px-4 py-2',
    orderHeaderButtonCSS: 'bg-fmYellow px-6 py-2 rounded-lg',
    orderListContainerCSS: 'flex flex-col gap-2',
    orderFooterCSS: 'flex flex-row justify-between items-center px-4 border-t border-gray-400 pt-8'
  }
});

const { orderCSS, orderHeaderCSS, orderHeaderColCSS, orderListContainerCSS, orderFooterCSS, orderHeaderButtonCSS } = orderComponent();


const Order = ({ order }) => {
  const { removeItemFromCart, addItemToCart, removeAllItemsOfType, calculateItemTotalPrice } = useCart();
  return (
    <div className={orderCSS()}>
      <div className={orderHeaderCSS()}>
        <div className={orderHeaderButtonCSS()}>Order - {order && order.id} - {order && order.status} </div>
        <div className={orderHeaderColCSS()} > Order Placed Thu. 5th Dec '24</div>
        <button className={orderHeaderButtonCSS()}>Track Order</button>
      </div>
      <div className={orderListContainerCSS()}>
        {order.line_items.map(item => (
          <OrderItem key={item.id} item={item} />
        ))}
      </div>
      <div className={orderFooterCSS()}>

        {/* Cancel order only if the order is in a certain state */}
        <button className='bg-red-500 px-4 rounded-lg py-2'>
          <FontAwesomeIcon icon={faXmark} /> Cancel Order
        </button>
        {/* How to determine the delivery */}
        <div>Payment Method: On Delivery</div>
        <div className='font-bold text-xl'>Total Price: {order && order.total} </div>
      </div>
    </div>
  )
}
export default Order;

